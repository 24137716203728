<h2 mat-dialog-title>{{ 'auditlogs.payload' | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <ngx-json-viewer [json]="payLoad"></ngx-json-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="lancrypt-primary"
          [cdkCopyToClipboard]="detail.payload">{{ 'auditlogs.copyToClipboard' | translate }}
  </button>
  <button mat-raised-button mat-dialog-close>{{ 'auditlogs.close' | translate }}</button>
</mat-dialog-actions>
