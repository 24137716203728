import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ClientsComponent} from './clients.component';
import {ImgDirective} from '@coreui/angular';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {RemoveIfFeatureIsOffDirective} from 'src/app/shared/remove-if-feature-is-off.directive';

@NgModule({
  declarations: [ClientsComponent],
  imports: [
    CommonModule,
    ImgDirective,
    MatCardModule,
    NgOptimizedImage,
    TranslateModule,
    RemoveIfFeatureIsOffDirective,
  ],
})
export class ClientsModule {}
