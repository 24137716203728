<div class="user-details-container">

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ displayName }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ userDetails?.emailAddress }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="user-details-content">

        <span *ngIf="userDetails?.connectionName !== null && userDetails?.connectionType !== null"
              class="connection-imported">
          {{
            'users.details.importedFromConnection' |
              translate: {connectionName: userDetails?.connectionName, connectionType: userDetails?.connectionType}
          }}
        </span>

        <div class="details-accordion">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'users.details.assets' | translate }}
                </mat-panel-title>
                <mat-panel-description *ngIf="displayName !== undefined">
                  {{
                    'users.details.assetsDescription' |
                      translate: {name: this.displayName, number: assetsTable?.dataSource?.data?.length}
                  }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <app-assets-table-details></app-assets-table-details>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="details-accordion">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'users.details.groups' | translate }}
                </mat-panel-title>
                <mat-panel-description *ngIf="displayName !== undefined">
                  {{
                    'users.details.groupsDescription' |
                      translate: {name: this.displayName, number: groupsTable?.dataSource?.data?.length}
                  }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <app-group-table-details></app-group-table-details>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="details-accordion">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'users.details.clients' | translate }}
                </mat-panel-title>
                <mat-panel-description *ngIf="displayName !== undefined">
                  {{
                    'users.details.clientsDescription' |
                      translate: {name: this.displayName, number: clientsTable?.dataSource?.data?.length}
                  }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <app-clients-table-details></app-clients-table-details>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="details-accordion">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'users.details.security' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ 'users.details.securityDescription' | translate }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <dl>
                <dt>
                  {{ 'users.details.lastSignIn' | translate }}
                </dt>
                <dd>
                  {{
                    (userDetails?.lastSignIn !== undefined) ?
                      (userDetails?.lastSignIn | date:'MMMM d, y HH:mm') : ('users.details.never' | translate)
                  }}
                </dd>
                <dt>
                  {{ 'users.details.lastPolicyRefresh' | translate }}
                </dt>
                <dd>
                  {{
                    (userDetails?.lastProfileFetch !== undefined) ?
                      (userDetails?.lastProfileFetch | date:'MMMM d, y HH:mm') : ('users.details.never' | translate)
                  }}
                </dd>
                <dt>
                  {{ 'users.identityProviders.signinMethod' | translate }}
                </dt>
                <dd>
                  {{ getIdentityProviderString() | translate }}
                </dd>
                <dd>
                  <button mat-raised-button color="lancrypt-primary"
                          (click)="resendActivation()"> {{ 'users.details.resendActivation' | translate }}
                  </button>
                </dd>
              </dl>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="control-elements" align="end">
      <button mat-raised-button class="" (click)="back()" disableDuringRequest>
        {{ 'buttons.back' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
