import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTree} from '@angular/material/tree';
import {GroupService, GroupTreeViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';
import {JwtHelperService} from 'src/app/services/helper/jwt-helper.service';
import {GroupTreeViewIconDto, SubFolderType} from 'src/app/dtos/lancrypt/GroupTreeViewIconDto';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GroupTreeService, GroupTreeTrait} from '../../../services/group-tree.service';

@Component({
  selector: 'app-lancrypt-identities',
  templateUrl: './lancrypt-identities.component.html',
  styleUrls: ['./lancrypt-identities.component.scss'],
})
export class LancryptIdentitiesComponent implements OnInit {
  @ViewChild('identitiesTree') identitiesTree!: MatTree<GroupTreeViewIconDto>;

  private groupApiClient: GroupService;

  subtypes = SubFolderType;

  showAssets = false;
  showMembers = false;
  showGroupView = false;

  rootName = '';
  foundGroups = '';
  foundGroupsIdx = 0;

  identitiesTreeData: GroupTreeViewIconDto[] = [];

  getChildren = (node: GroupTreeViewIconDto) => node.children ?? [];
  hasChild = (_: number, node: GroupTreeViewIconDto) => !!node.children && node.children.length > 0;
  trackBy = (_: number, node: GroupTreeViewIconDto) => this.expansionKey(node);
  expansionKey = (node: GroupTreeViewIconDto) => node.id;

  activeNode: GroupTreeViewIconDto | undefined;

  SubFolderType = SubFolderType;

  constructor(
    private translationService: TranslateService,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private jwtHelperService: JwtHelperService,
    private groupTreeService: GroupTreeService
  ) {
    this.groupApiClient = this.apiClientFactory.getGroupService();
  }

  async ngOnInit() {
    await this.load();
  }

  async load() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();
    this.groupApiClient.getGroupTreeByTenantId(tenantId).subscribe({
      next: async (n: Array<GroupTreeViewDto>) => {
        if (!n) {
          this.toastService.showInfo(
            this.translationService.instant('common.info'),
            this.translationService.instant('infos.nogroupsFoundForTenant')
          );
          return;
        }

        const tree = await this.groupTreeService.buildTreeView(n, GroupTreeTrait.All);

        // Add node for importing groups and users
        const importFolder: GroupTreeViewIconDto = {
          id: '',
          name: this.translationService.instant('buttons.importUsers'),
          children: [],
          icon: 'add_circle_outline',
          subType: SubFolderType.LoadMoreUsers,
          parents: [],
          description: '',
          treeHierarchyDirection: 'ANCESTORS',
          root: false,
          syncedGroup: false,
          identityProviderConnectionId: '',
        };
        tree.push(importFolder);

        // Set tree as datasource for tree control
        this.identitiesTreeData = tree;
        this.identitiesTree.dataSource = [];
        this.identitiesTree.dataSource = this.identitiesTreeData;

        // Expand all root nodes
        this.identitiesTreeData.forEach(node => {
          if (node.root) {
            this.identitiesTree.expand(node);
          }
        });

        this.moveToNodeBasedOnParams();
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('errors.gettingGroups')
        );
      },
      complete: () => {},
    });
  }

  moveToNodeBasedOnParams() {
    if (this.route.snapshot.url.pop()!.path === 'users') {
      const usersNode = this.findFirst(this.identitiesTreeData, x => x.subType === SubFolderType.AllUsers);

      if (usersNode) {
        this.nodeSelected(usersNode);
      } else {
        this.nodeSelected(this.identitiesTreeData[0]);
      }

      return;
    }

    this.route.params.subscribe(params => {
      const groupId = params['groupId']?.toLowerCase();
      let subfolder = parseInt(params['subfolder']);

      // First find group node, then find matching subfolder node type
      let groupNode = this.findFirst(this.identitiesTreeData, x => x.id === groupId);

      if (!groupNode) {
        // Not found -> select first root group
        groupNode = this.identitiesTreeData[0];
      }

      // For root nodes there is no 'Members' node but a 'Users' node
      if (groupNode.root && subfolder === SubFolderType.Members) {
        subfolder = SubFolderType.Users;
      }

      const node =
        subfolder === SubFolderType.Group ? groupNode : groupNode.children.find(x => x.subType === subfolder);
      this.identitiesTree.expand(groupNode);
      this.nodeSelected(node ? node : groupNode);

      // If it is a sub-group, we need to expand the nodes above as well
      if (!groupNode.root) {
        this.expandParents(groupNode);
      }
    });
  }

  private expandParents(node: GroupTreeViewIconDto): void {
    if (node.root) {
      return;
    }

    const parents = this.findAll(
      this.identitiesTreeData,
      n => n.children && !!n.children.find(child => child.id === node.id)
    );
    for (const parent of parents) {
      this.identitiesTree.expand(parent);

      if (!parent.root) {
        this.expandParents(parent);
      }
    }
  }

  nodeSelected(node: GroupTreeViewIconDto) {
    this.activeNode = node;

    // Get root for selected node
    const root = this.getRoot(node);
    this.rootName = root?.name ?? '';

    // Show data selected for sub folder type and update URL in browser.
    switch (node.subType) {
      case SubFolderType.Assets:
        this.location.go('/lancrypt/identities/group/' + node.parents[0].id + '/' + SubFolderType.Assets);
        this.showAssets = true;
        this.showMembers = false;
        this.showGroupView = false;
        break;
      case SubFolderType.Group:
        this.location.go('/lancrypt/identities/group/' + node.id + '/' + SubFolderType.Group);
        this.showAssets = false;
        this.showMembers = false;
        this.showGroupView = true;
        break;
      case SubFolderType.Users:
        this.location.go('/lancrypt/identities/group/' + node.parents[0].id + '/' + SubFolderType.Users);
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.AllUsers:
        this.location.go('/lancrypt/identities/users');
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.Members:
        this.location.go('/lancrypt/identities/group/' + node.parents[0].id + '/' + SubFolderType.Members);
        this.showAssets = false;
        this.showMembers = true;
        this.showGroupView = false;
        break;
      case SubFolderType.LoadMoreUsers:
        // Navigate to create connection view
        this.router.navigate(['lancrypt', 'connections', 'create-connection']);
        break;
      case SubFolderType.Undefined:
      default:
        this.showAssets = false;
        this.showMembers = false;
        this.showGroupView = false;
        break;
    }
  }

  async groupDeleted() {
    await this.load();
  }

  jumpToNode(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue === '') {
      this.nodeSelected(this.identitiesTreeData[0]);
      this.foundGroupsIdx = 0;
      this.foundGroups = '';

      return;
    }

    const nodes = this.findAll(
      this.identitiesTreeData,
      node => node.subType === SubFolderType.Group && node.name.toLowerCase().includes(filterValue.toLowerCase())
    );

    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (this.foundGroupsIdx < nodes.length - 1) {
        this.foundGroupsIdx++;
      } else {
        this.foundGroupsIdx = 0;
      }
    }

    this.foundGroups = `${nodes.length === 0 ? 0 : this.foundGroupsIdx + 1}/${nodes.length}`;

    if (nodes.length > 0) {
      this.nodeSelected(nodes[this.foundGroupsIdx]);
      this.expandParents(nodes[this.foundGroupsIdx]);
    }
  }

  isActiveNode = (node: GroupTreeViewIconDto) => node.id === this.activeNode?.id;

  private findAll(
    nodes: GroupTreeViewIconDto[],
    predicate: (item: GroupTreeViewIconDto) => boolean
  ): GroupTreeViewIconDto[] {
    const result: GroupTreeViewIconDto[] = [];

    for (const node of nodes) {
      if (predicate.call(undefined, node)) {
        result.push(node);
      }

      if (node.children && node.children.length > 0) {
        const childrenResult = this.findAll(node.children, predicate);
        if (childrenResult && childrenResult.length > 0) {
          result.push(...childrenResult);
        }
      }
    }

    return result;
  }

  private findFirst(
    nodes: GroupTreeViewIconDto[],
    predicate: (item: GroupTreeViewIconDto) => boolean
  ): GroupTreeViewIconDto | null {
    for (const node of nodes) {
      if (predicate.call(undefined, node)) {
        return node;
      }

      if (node.children && node.children.length > 0) {
        const childrenResult = this.findFirst(node.children, predicate);
        if (childrenResult) {
          return childrenResult;
        }
      }
    }

    return null;
  }

  private getRoot(node: GroupTreeViewIconDto): GroupTreeViewIconDto | null {
    for (const root of this.identitiesTreeData) {
      if (root.children && !!this.findFirst(root.children, x => x.id === node.id)) {
        return root;
      }
    }
    return null;
  }
}
