<c-container class="outer-container create-edit-container">
  <mat-card>
    <mat-card-header class="title-card-header">
      <mat-card-title>
        {{ 'personalInfo.edit.tenant.updateTenant' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="dense-form-field-2">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.street1' | translate }}</mat-label>
          <input matInput formControlName="street1" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','street1')">
            {{ 'personalInfo.edit.tenant.error.street1MaxLength' | translate: {maxLength: maxLengthConstraints.street1} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.street2' | translate }}</mat-label>
          <input matInput formControlName="street2">
          <mat-error *ngIf="formGroup.hasError('maxlength','street2')">
            {{ 'personalInfo.edit.tenant.error.street2MaxLength' | translate: {maxLength: maxLengthConstraints.street2} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.city' | translate }}</mat-label>
          <input matInput formControlName="city" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','city')">
            {{ 'personalInfo.edit.tenant.error.cityMaxLength' | translate: {maxLength: maxLengthConstraints.city} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.state' | translate }}</mat-label>
          <input matInput formControlName="state" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','state')">
            {{ 'personalInfo.edit.tenant.error.stateMaxLength' | translate: {maxLength: maxLengthConstraints.state} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.postalCode' | translate }}</mat-label>
          <input matInput formControlName="postalCode" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','postalCode')">
            {{ 'personalInfo.edit.tenant.error.postalCodeMaxLength' | translate: {maxLength: maxLengthConstraints.postalCode} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.tenant.country' | translate }}</mat-label>
          <input matInput formControlName="country" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','country')">
            {{ 'personalInfo.edit.tenant.error.countryMaxLength' | translate: {maxLength: maxLengthConstraints.country} }}
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="control-elements" align="end">
      <button mat-raised-button color="lancrypt-primary" (click)="onSave()" disableDuringRequest>
        {{ 'common.save' | translate }}
      </button>
      <button mat-raised-button (click)="onCancel()" disableDuringRequest>
        {{ 'common.cancel' | translate }}
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</c-container>
