import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-text-confirm-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './text-confirm-dialog.component.html',
  styleUrl: './text-confirm-dialog.component.scss',
})
export class TextConfirmDialogComponent {
  userInput = new FormControl('', [
    Validators.required,
    TextConfirmDialogComponent.inputValidator(this.data.controlValue),
  ]);

  constructor(
    public dialogRef: MatDialogRef<TextConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  activate() {
    if (this.userInput.hasError('invalidInput')) {
      return;
    }
    this.dialogRef.close(true);
  }

  static inputValidator(controlValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const content = (control.value as string).trim().toLowerCase();

      if (content === controlValue) {
        return null;
      }

      return {invalidInput: {invalid: true}};
    };
  }
}

export interface DialogData {
  controlValue: string;
}
