<h2 mat-dialog-title>{{ 'settings.keyServer.activation.heading' | translate }}</h2>
<mat-dialog-content class="dialog-description">
  <p>
    {{ 'settings.keyServer.activation.p1' | translate }}
  </p>
  <p>
    {{ 'settings.keyServer.activation.p2' | translate }}
  </p>
  <p>
    {{ 'settings.keyServer.activation.p3' | translate }}
  </p>
  <h3>{{ 'settings.keyServer.activation.prompt' | translate }}</h3>
  <mat-form-field>
    <input
      matInput
      placeholder="{{ 'settings.keyServer.activation.placeholder' | translate }}"
      [formControl]="userInput"
    />
    <mat-error *ngIf="userInput.hasError('invalidInput')">
      {{ 'settings.keyServer.activation.inputError' | translate}}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="closeDialog()">{{ 'buttons.cancel' | translate }}</button>
  <button mat-button [disabled]="userInput.hasError('invalidInput')" (click)="activate()">{{ 'buttons.activate' | translate }}</button>
</mat-dialog-actions>
