<div class="assets-view-container">
  <div class="assets-view-header">
        <div class="side-view-header">
          <h2>{{'headlines.assets' | translate}}</h2>
          <p class="subheadline">{{'subheadline.membersOfAssets' | translate: {name: activeNode?.parents?.[0]?.name} }}</p>
        </div>
        <div class="control-elements">
          <button matTooltip="{{ 'buttons.assignAssets' | translate }}" mat-mini-fab color="lancrypt-primary" [routerLink]="['/lancrypt/identities/assets/assign/group', activeNode?.parents?.[0]?.id]">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
  </div>
  <div class="assets-view-body">
    <app-assets-table-groups [activeNode]="activeNode"></app-assets-table-groups>
  </div>
</div>
