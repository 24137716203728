import {GroupTreeViewDto} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';

export interface GroupTreeViewIconDto extends GroupTreeViewDto {
  icon: string;
  children: Array<GroupTreeViewIconDto>;
  subType: SubFolderType;
}

export enum SubFolderType {
  Assets,
  Members,
  Users,
  Undefined,
  Group,
  LoadMoreUsers,
  AllUsers,
  User,
}
