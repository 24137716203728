<c-container class="outer-container create-edit-container">
  <mat-card>
    <mat-card-header class="title-card-header">
      <mat-card-title>
        {{ 'personalInfo.edit.basic.updateBasic' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="dense-form-field-2">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.basic.firstName' | translate }}</mat-label>
          <input matInput formControlName="firstName" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','firstName')">
            {{ 'personalInfo.edit.basic.error.firstNameMaxLength' | translate: {maxLength: maxLengthConstraints.firstName} }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'personalInfo.edit.basic.lastName' | translate }}</mat-label>
          <input matInput formControlName="lastName" required>
          <mat-error *ngIf="formGroup.hasError('maxlength','lastName')">
            {{ 'personalInfo.edit.basic.error.lastNameMaxLength' | translate: {maxLength: maxLengthConstraints.lastName} }}
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions class="control-elements" align="end">
      <button mat-raised-button color="lancrypt-primary" (click)="onSave()" disableDuringRequest>
        {{ 'common.save' | translate }}
      </button>
      <button mat-raised-button (click)="onCancel()">{{ 'common.cancel' | translate }}</button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</c-container>
