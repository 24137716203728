import {Component, Input, ViewChild} from '@angular/core';
import {AssetsTableGroupsComponent} from '../assets-table-groups/assets-table-groups.component';
import {GroupTreeViewIconDto} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';

@Component({
  selector: 'app-assets-view',
  templateUrl: './assets-view.component.html',
  styleUrls: ['./assets-view.component.scss'],
})
export class AssetsViewComponent {
  @Input() activeNode: GroupTreeViewIconDto | undefined;
  @ViewChild(AssetsTableGroupsComponent)
  assetsTable?: AssetsTableGroupsComponent;
}
