<div class="groups-view-container">
  <div class="groups-view-header">
    <div class="side-view-header">
      <h2>
        {{ activeNode?.name }}
        <span class="clickable" *ngIf="showEdit()" (click)="edit()">
          <mat-icon title="{{'common.edit' | translate}}">edit</mat-icon>
        </span>
      </h2>
      <p class="subheadline">{{ !(activeNode?.root ?? true) ? rootName : '' }}</p>
      <p class="subheadline" *ngIf="!!activeNode?.description">{{ activeNode?.description }}</p>
    </div>
    <div class="control-elements" *ngIf="!activeNode?.syncedGroup">
      <button matTooltip="{{ 'buttons.addGroup' | translate }}" mat-mini-fab color="lancrypt-primary"
              [routerLink]="['/lancrypt/identities/create-group/' + activeNode?.id+'/'+ activeNode?.name]">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="groups-view-body">
    <h3>{{ 'groups.groupTableTitle' | translate }}</h3>
    <app-group-table-subgroups [groupId]="activeNode?.id"
                               (groupDeleted)="subGroupDeleted()"></app-group-table-subgroups>
  </div>
</div>

